import { APP_INITIALIZER, inject } from '@angular/core';
import { injectRpcClient } from '@nest/common/core/frontend';
import {
	InventoryItemDto,
	InventoryItemEntityName,
	ItemCategoryDto,
	ItemCategoryEntityName,
	ItemDto,
	ItemEntityName,
	ItemMovementDto,
	ItemMovementEntityName
} from '@nest/domain/entities-metadata/dtos';
import { inventoryRouter } from '@nest/domain/inventory/shared';
import { EntityDetailLinkPathRendererComponent, EntityMetadataService, EntityService, fieldsToElements } from '@nest/uikit/entity-presentation/ui';
import { autoPathsFor } from '@nest/utils/shared';
import { from } from 'rxjs';

const paths = autoPathsFor<ItemDto>();

export const ItemDefaultElements = fieldsToElements(paths(['code', 'name', 'quantity', 'ean', 'manufacturer.name', 'manufacturerCode']));

export function provideItemsEntities() {
	return [
		{
			provide: APP_INITIALIZER,
			multi: true,
			useFactory: () => {
				let entityMetadata = inject(EntityMetadataService);
				const entityService = inject(EntityService);
				const client = injectRpcClient(inventoryRouter);
				// let manufacturerDataAccess = inject(ManufacturerDataAccessService);
				// let itemCollectionDataAccess = inject(ItemCollectionDataAccessService);
				// let productCodeTypeDataAccess = inject(ProductCodeTypeDataAccessService);

				entityService.registerEntity({
					label: 'Zboží',
					key: 'items',
					entityName: ItemEntityName,
					previewLabelFn: item => `${item.code} - ${item.name}`,
					linkFn: id => (id ? ['/items', 'detail', id] : ['/items']),
					searchFn: term =>
						from(
							client.inventory.items.getAll({
								filter: { name: { $ilike: term } },
								limit: 10
							})
						)
				});

				return () => {
					// entityMetadata.registerEntityManufacturerDto({
					// 	entityName: ManufacturerEntityName,
					// 	dataAccess: manufacturerDataAccess,
					// 	defaultElements: fieldsToElements(autoPathsFor<ManufacturerDto>()(['id']))
					// });
					entityMetadata.registerEntity({
						entityName: InventoryItemEntityName,
						defaultElements: fieldsToElements(autoPathsFor<InventoryItemDto>()(['id']))
					});

					entityMetadata.registerEntity({
						entityName: ItemCategoryEntityName,
						defaultElements: fieldsToElements(autoPathsFor<ItemCategoryDto>()(['id']))
					});

					// entityMetadata.registerEntityItemCollectionDto({
					// 	entityName: ItemCollectionEntityName,
					// 	dataAccess: itemCollectionDataAccess,
					// 	defaultElements: fieldsToElements(autoPathsFor<ItemCollectionDto>()(['id']))
					// });

					entityMetadata.registerEntity({
						entityName: ItemMovementEntityName,
						defaultElements: fieldsToElements(autoPathsFor<ItemMovementDto>()(['id']))
					});

					entityMetadata.registerEntity({
						entityName: ItemEntityName,
						defaultElements: ItemDefaultElements
					});
					// entityMetadata.registerEntityProductCodeTypeDto({
					// 	entityName: ProductCodeTypeEntityName,
					// 	dataAccess: productCodeTypeDataAccess,
					// 	defaultElements: fieldsToElements(autoPathsFor<ProductCodeTypeDto>()(['id']))
					// });

					entityMetadata.registerEntityPathRenderer(ItemEntityName, paths(['code', 'name']), EntityDetailLinkPathRendererComponent);
				};
			}
		}
	];
}
