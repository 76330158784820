export enum ItemType {
	Catalog = 'Catalog', // Katalogova polozka, skladove karty jsou uzce svazany (Tirex Pneu)
	Root = 'Root', // Nahrazena polozka skladovym kartam, ktere se resi separatne (Uskladnenky)
	Fee = 'Fee', // Položka příplatku, např. dopravné a balné, recyklační poplatek apod.
	Rounding = 'Rounding'
}

export enum TireSeason {
	Summer = 'Summer',
	Winter = 'Winter',
	AllSeason = 'AllSeason'
}

export enum ItemImportType {
	Item = 'Item',
	ItemParametersTire = 'ItemParametersTire'
}

export const ItemJobEvents = {
	ItemImportCompleted: 'ItemImportCompleted'
};
